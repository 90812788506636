<template>
  <cp-confirm-modal
    ref="cpConfirmModal"
    :title="$t('investorsCommon.button.investor.delete')"
    :item="item"
    @onOk="removeInvestor"
  >
    {{ confirmModalText }}
  </cp-confirm-modal>
</template>

<script>
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'RemoveInvestor',
  components: {
    CpConfirmModal,
  },
  props: {
    item: {
      type: Object,
      default: (() => {}),
    },
  },
  computed: {
    confirmModalText() {
      return this.$t('investorsCommon.message.deleteConfirmation', [this.getInvestorName]);
    },
    getInvestorName() {
      const {
        investorType, entityName, companyName, firstName, middleName, lastName, fullName,
      } = this.item;
      const resultName = fullName || `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
      if (investorType === 'entity' || (investorType || '').toLowerCase() === 'entity') {
        return `${entityName || companyName}(${resultName})`;
      }
      return resultName;
    },
  },
  methods: {
    removeInvestor() {
      this.$emit('deleteIsApproved', {
        issuerId: this.$route.params.idIssuer,
        userId: this.item.id,
      });
    },
    show() {
      this.$refs.cpConfirmModal.show();
    },
  },
};
</script>
